import ApiService from '@/services/api.service'

export const FETCH_COMMUNITY_CUSTOM_EMAIL_HISTORY =
  'FETCH_COMMUNITY_CUSTOM_EMAIL_HISTORY'
export const FETCH_SCHEDULED_EMAILS = 'FETCH_SCHEDULED_EMAILS'
export const DELETE_SCHEDULED_EMAIL = 'DELETE_SCHEDULED_EMAIL'

const actions = {
  async [FETCH_COMMUNITY_CUSTOM_EMAIL_HISTORY](context, payload) {
    try {
      const url = `/experienceLabs/client/community/${context.getters.communityId}/emailHistory?page=${payload.page}&pageSize=${payload.pageSize}`

      const response = await ApiService.post(url, {
        filter: payload.filter,
        value: payload.value,
      })
      return response.data
    } catch (error) {
      console.error('Error al obtener emails:', error)
      return error
    }
  },

  async [FETCH_SCHEDULED_EMAILS](context, payload) {
    try {
      const url = `/experienceLabs/client/community/${context.getters.communityId}/scheduledEmails?page=${payload.page}&pageSize=${payload.pageSize}`

      const response = await ApiService.post(url, {
        filter: payload.filter,
        value: payload.value,
      })
      return response.data
    } catch (error) {
      console.error('Error al obtener correos programados:', error)
      return error
    }
  },

  async [DELETE_SCHEDULED_EMAIL](context, payload) {
    try {
      const url = `/experienceLabs/client/community/${context.getters.communityId}/scheduledEmails/${payload.id.toString()}`

      const response = await ApiService.delete(url)
      return response.data
    } catch (error) {
      console.error('Error al eliminar correo programado:', error)
      return error
    }
  },
}

export default {
  actions,
}
