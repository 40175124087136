import CommunityInfoModule from './info.module';
import CommunityMembersModule from './members.module';
import CommunityActivitiesModule from './activities.module';
import EmailHistoryModule from './emailHistory.module';
import CommunityTagsModule from './tags.module';
import CommunityChatModule from './chat.module';
import CommunityRedeemModule from './redeem.module';
import CommunityModeratorsModule from './moderators.module';

const initialState = {
  ...CommunityInfoModule.state,
  ...CommunityMembersModule.state,
  ...CommunityActivitiesModule.state,
  ...EmailHistoryModule.state,
  ...CommunityTagsModule.state,
  ...CommunityChatModule.state,
  ...CommunityRedeemModule.state,
  ...CommunityModeratorsModule.state,
};

const state = initialState;

const getters = {
  ...CommunityInfoModule.getters,
  ...CommunityMembersModule.getters,
  ...CommunityActivitiesModule.getters,
  ...EmailHistoryModule.getters,
  ...CommunityTagsModule.getters,
  ...CommunityChatModule.getters,
  ...CommunityRedeemModule.getters,
  ...CommunityModeratorsModule.getters,
};

const actions = {
  ...CommunityInfoModule.actions,
  ...CommunityMembersModule.actions,
  ...CommunityActivitiesModule.actions,
  ...EmailHistoryModule.actions,
  ...CommunityTagsModule.actions,
  ...CommunityChatModule.actions,
  ...CommunityRedeemModule.actions,
  ...CommunityModeratorsModule.actions,
};

const mutations = {
  ...CommunityInfoModule.mutations,
  ...CommunityMembersModule.mutations,
  ...CommunityActivitiesModule.mutations,
  ...EmailHistoryModule.mutations,
  ...CommunityTagsModule.mutations,
  ...CommunityChatModule.mutations,
  ...CommunityRedeemModule.mutations,
  ...CommunityModeratorsModule.mutations,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
