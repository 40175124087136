import ApiService from '@/services/api.service';
import MockUp     from './mockNotifications';

export const NOTIFICATIONS_FETCH_STATE                  = 'NOTIFICATIONS_FETCH_STATE';
export const NOTIFICATIONS_CLEAR_ID                     = 'NOTIFICATIONS_CLEAR_ID';
export const NOTIFICATIONS_CLEAR_POPUP_ID               = 'NOTIFICATIONS_CLEAR_POPUP_ID';
export const NOTIFICATIONS_SET_STATE                    = 'NOTIFICATIONS_SET_STATE';
export const NOTIFICATIONS_SET_STATE_LOADING            = 'NOTIFICATIONS_SET_STATE_LOADING';
export const NOTIFICATIONS_SET_STATE_ERROR              = 'NOTIFICATIONS_SET_STATE_ERROR';

export const NOTIFICATIONS_FETCH_PAGINATED              = 'NOTIFICATIONS_FETCH_PAGINATED'
export const NOTIFICATIONS_SET_PAGINATED                = 'NOTIFICATIONS_SET_PAGINATED'
export const NOTIFICATIONS_SET_LOADING_PAGINATED        ='NOTIFICATIONS_SET_LOADING_PAGINATED'
export const NOTIFICATIONS_SET_ERROR_PAGINATED          ='NOTIFICATIONS_SET_ERROR_PAGINATED'


const initialState = {
  notifications: {
    state: [],
    loading: true,
    error: null,
  },
  paginatedNotifications: {
    alerts: [],
    loading: false,
    error: null,
    page: 0,
    pageSize: 10,
    total: 0,
  },
}

const state = initialState;

const getters = {
  loadingNotifications ( state ) {
    return state.notifications.loading;
  },

  notifications ( state ) {
    // Just for testing.
    // return MockUp.alerts;
    return state.notifications.state ? state.notifications.state.alerts : [];
  },

  unseenNotifications ( state, getters ) {
    return getters.notifications
      ? getters.notifications.filter( data => !data.seen )
      : []
  },

  unseenNotifCount ( state, getters ) {
    // Just for testing.
    // return String( MockUp.unseenAlerts );
    return state.notifications.state && state.notifications.state.unseenAlerts;
  },
  paginatedAlerts(state) {
    return state.paginatedNotifications.alerts || [];
  },
  loadingPaginatedAlerts(state) {
    return state.paginatedNotifications.loading;
  },
  paginatedCurrentPage(state) {
    return state.paginatedNotifications.page;
  },
  paginatedPageSize(state) {
    return state.paginatedNotifications.pageSize;
  },
  paginatedTotal(state) {
    return state.paginatedNotifications.total;
  },
};

const actions = {
  [NOTIFICATIONS_FETCH_STATE] ( context, { isClient, hideSpinner } ) {
    const cID = context.getters.communityId;
    const endpoint = isClient
      ? `/experienceLabs/client/communities/${ cID }/alerts/page/0/pageSize/100`
      : `/experienceLabs/notifications/${ cID }/alerts/page/0/pageSize/100`;

    if (!hideSpinner) {
      context.commit( NOTIFICATIONS_SET_STATE_LOADING );
    }

    return ApiService
      .get( endpoint )
      .then( ( { data } ) => {
        context.commit( NOTIFICATIONS_SET_STATE, data );
        return data;
      })
      .catch( ( { response } ) => {
        context.commit( NOTIFICATIONS_SET_STATE_ERROR, response );
        return response;
      } );
  },

  [NOTIFICATIONS_CLEAR_ID] ( context, payload ) {
    const { ids, isClient, hideSpinner } = payload;
    const endpoint = isClient
      ? '/experienceLabs/client/notifications/seen'
      : '/experienceLabs/notifications/seen';

    return ApiService
      .post( endpoint, { "idsToSee": ids } )
      .then( async ( { data } ) => {
        await context.dispatch( NOTIFICATIONS_FETCH_STATE, { isClient, hideSpinner } );
        return data;
      })
      .catch( ( { response } ) => {
        context.commit( NOTIFICATIONS_SET_STATE_ERROR, response );
        return response;
      } );
  },
  [NOTIFICATIONS_CLEAR_POPUP_ID] ( context, payload ) {
    const { ids } = payload;
    const endpoint ='/experienceLabs/notifications/seenPopup';

    return ApiService
      .post( endpoint, { "idsToSee": ids } )
      .then( async ( { data } ) => {
        await context.dispatch( NOTIFICATIONS_FETCH_STATE, { isClient: false } );
        return data;
      })
      .catch( ( { response } ) => {
        context.commit( NOTIFICATIONS_SET_STATE_ERROR, response );
        return response;
      } );
  },
  async [NOTIFICATIONS_FETCH_PAGINATED]({ commit, state, getters }, { page, pageSize, isClient, hideSpinner }) {
    if (!hideSpinner) {
      commit(NOTIFICATIONS_SET_LOADING_PAGINATED);
    }
    try {
      const cID = getters.communityId;
      const endpoint = isClient
        ? `/experienceLabs/client/communities/${cID}/alerts/page/${page}/pageSize/${pageSize}/withPagination`
        : `/experienceLabs/notifications/${cID}/alerts/page/${page}/pageSize/${pageSize}/withPagination`;

      const { data } = await ApiService.get(endpoint);

      console.log("data:", data)
      commit(NOTIFICATIONS_SET_PAGINATED, {
        alerts: data.alerts,
        page,
        pageSize,
        total: data.totalElements ?? data.alerts.length, 
      });
      return data;
    } catch (error) {
      const response = error.response;
      commit(NOTIFICATIONS_SET_ERROR_PAGINATED, response);
      return response;
    }
  },
};

const mutations = {
  [NOTIFICATIONS_SET_STATE] ( state, payload ) {
    state.notifications.state = payload;
    state.notifications.loading = false;
    state.notifications.error = null;
  },

  [NOTIFICATIONS_SET_STATE_LOADING] ( state ) {
    state.notifications.loading = true;
  },

  [NOTIFICATIONS_SET_STATE_ERROR] ( state, error ) {
    state.notifications.error = error;
    state.notifications.loading = false;
  },

  [NOTIFICATIONS_SET_PAGINATED](state, { alerts, page, pageSize, total }) {
    state.paginatedNotifications.alerts = alerts;
    state.paginatedNotifications.page = page;
    state.paginatedNotifications.pageSize = pageSize;
    state.paginatedNotifications.total = total;
    state.paginatedNotifications.error = null;
    state.paginatedNotifications.loading = false;
  },
  [NOTIFICATIONS_SET_LOADING_PAGINATED](state) {
    state.paginatedNotifications.loading = true;
  },
  [NOTIFICATIONS_SET_ERROR_PAGINATED](state, error) {
    state.paginatedNotifications.error = error;
    state.paginatedNotifications.loading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};